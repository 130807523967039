import {
  AppstoreAddOutlined,
  BarChartOutlined,
  BellOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  FolderOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  OrderedListOutlined,
  RiseOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined
} from '@ant-design/icons';
import { winAccountForm, winStockForm } from '../serverInfo/backendModel';

// Be careful to modify the value !!!
export const AccountingObjectTypeID = {
  User: '0000',
  Customer: '0001',
  Supplier: '0002',
  WorkerProvider: '0003'
} 

export const CardTitleButton = {
  None: 'None',
  Add: 'Add',
  //Child_Add: 'Child_Addd',
  Export: 'Export',
  Import: 'Import',
  Import_Add: 'Import_Add',
  Import_Or_Modify_Delete: 'Import_Or_Modify_Delete',
  Inventory_StockOut_StockIn: 'Inventory_StockOut_StockIn',
  Modify: 'Modify',
  Link: 'Link',
  Link_Add: 'Link_Add',
  Link_Add_UnLink_Modify: 'Link_Add_UnLink_Modify',
  Menu: 'Menu',
  Settle: 'Settle',
  Statement_Export: 'Statement_Export',
  StatementSupplier: 'StatementSupplier',
  Transfer_Add: 'Transfer_Add',
}

export const CardTitleStyle = {
  None: 'None',
  BaseModelName_DataCallName_DataCount: 'BaseModelName_DataCallName_DataCount',
  CategoryName_DataCount: 'CategoryName_DataCount',
  DataCallName: 'DataCallName',
  DataCallName_DataCount: 'DataCallName_DataCount',
  DataCallName_DataCount_OnJobCount: 'DataCallName_DataCount_OnJobCount',
  TitleOnly: 'TitleOnly',
  Title_DataCount: 'Title_DataCount'
}

export const FormFormat = {
  OneColumn: 'OneColumn',
  TwoColumns: 'TwoColumns',
  ThreeColumns: 'ThreeColumns',
  ThreeColumnsCheck: 'ThreeColumnsCheck',
  FourColumns: 'FourColumns',
  SixColumns: 'SixColumns',
  FullRow: 'FullRow',
  ListData: 'ListData'
}

export const FormComponentType = {
  checkbox: 'checkbox',
  checkbox_label: 'checkbox_label',
  date: 'date',
  dateMonth: 'dateMonth',
  input: 'input',
  mentions: 'mentions',
  multiselect: 'multiselect',
  period: 'period',
  radio: 'radio',
  select: 'select',
  switch: 'switch',
  text: 'text',
  upload: 'upload'
}

export const AccountFlow = {
    Income: '0000',
    Expenditure: '0001'
}

export const ExportType = {
  System: '0',
  SystemYear: '1',
  SystemMonth: '2',
  
  One: '20',
  OneYear: '21',
  OneMonth: '22',
  
  Overview: '100',
  OverviewYear: '101',
  OverviewMonth: '102',
  
  OverviewCustomer: '103',
  OverviewSupplier: '104',
  OverviewWorkerProvider: '105',
  OverviewUser: '106',
  OverviewProject: '107',

  PeriodMonth: '150',
  
  StatementOneMonth: '200',
  StatementOneMonthSupplier: '201',
  
  Interplay: '900',
}

export const ItemMatchingField = {
  ID: 'ID',
  ObjectID: 'ObjectID',
  ObjectRelated: 'ObjectRelated',
  ListObjectRelated: 'ListObjectRelated'
}

export const ItemMatchingOutputType = {
  Abbreviation: 'Abbreviation',
  Brand: 'Brand',
  //CID_User: 'CID_User',
  ColorTag: 'ColorTag',
  ColorTag_Abbreviation: 'ColorTag_Abbreviation',
  ContractedDate: 'ContractedDate',
  Level: 'Level',
  ListInlineColorTag: 'ListInlineColorTag',
  Model: 'Model',
  Name: 'Name',
  Object: 'Object',
  ObjectName: 'ObjectName',
  Project: 'Project',
  Stage1: 'Stage1',
  Stage2: 'Stage2',
  Stage3: 'Stage3',
  Stage4: 'Stage4',
  Stage5: 'Stage5',
  Stage6: 'Stage6',
  SysColorTag: 'SysColorTag',
}

export const ProgressStatusLevelType = {
  Level3: '0003',
  Level4: '0004',
  Level5: '0005',
  Level6: '0006'
}
  
export const ModuleID = {
  Login: '0',
  Profile: '1',
  Task: '2',
  Project: '3',
  Customer: '4',
  Supplier: '5',
  Worker: '6',
  Document: '7',
  HR: '8',
  Financial: '9',
  Accounts: '10',
  Transaction: '11',
  AccountsSalary: '12',
  Receipt: '13',
  Setting: '14',
  SettingGeneral: '15',
  Company: '16',
  SettingBatch: '17',
  SettingProject: '18',
  SettingHR: '19',
  SettingFinancial: '20',
  Statistic: '21',
  ProjectProgress: '22',
  ProjectProgress_I: '23',
  ProjectProgress_I2: '24',
  ProjectProgress_P: '25',
  StorehouseAll: '26',
  Inventory: '27',
  Storehouse: '28',
  Material: '29',
  Test: '30',
}
  
export const ModuleInfo = [
  {
    name: 'Login',
    url: '/login',
    sidebarId: ModuleID.Login,
    sidebarIcon: '',
    isRouteExact: false,
    isEnableOnSideMenu: false,
    upperItem: undefined
  },
  {
    name: 'Profile',
    url: '/home/profile',
    sidebarId: ModuleID.Profile,
    sidebarIcon: <UserOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Task',
    url: '/home/task',
    sidebarId: ModuleID.Task,
    sidebarIcon: <OrderedListOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Project',
    url: '/home/project',
    sidebarId: ModuleID.Project,
    sidebarIcon: <FolderOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Customer',
    url: '/home/customer',
    sidebarId: ModuleID.Customer,
    sidebarIcon: <BellOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Supplier',
    url: '/home/supplier',
    sidebarId: ModuleID.Supplier,
    sidebarIcon: <ShoppingCartOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Worker',
    url: '/home/worker',
    sidebarId: ModuleID.Worker,
    sidebarIcon: <ToolOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Document',
    url: '/home/document',
    sidebarId: ModuleID.Document,
    sidebarIcon: <TagOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'HR',
    url: '/home/hr',
    sidebarId: ModuleID.HR,
    sidebarIcon: <TeamOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Financial',
    url: '/home/financial',
    sidebarId: ModuleID.Financial,
    sidebarIcon: <MenuUnfoldOutlined />,
    isRouteExact: true,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Accounts',
    url: '/home/accounts',
    sidebarId: ModuleID.Accounts,
    sidebarIcon: <DollarCircleOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Financial
  },
  {
    name: 'TransactionForm',
    url: '/home/transactionform',
    sidebarId: ModuleID.Transaction,
    sidebarIcon: <DollarCircleOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Financial
  },
  {
    name: 'AccountsSalary',
    url: '/home/accountsSalary',
    sidebarId: ModuleID.AccountsSalary,
    sidebarIcon: <DollarCircleOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Financial
  },
  {
    name: 'ReceiptOperation',
    url: '/home/accountsReceipt',
    sidebarId: ModuleID.Receipt,
    sidebarIcon: <DollarCircleOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Financial
  },
  {
    name: 'Setting',
    url: '/home/setting',
    sidebarId: ModuleID.Setting,
    sidebarIcon: <ControlOutlined />,
    isRouteExact: true,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'General', // SettingGeneral
    url: '/home/setting/general',
    sidebarId: ModuleID.SettingGeneral,
    sidebarIcon: <AppstoreAddOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'Company',
    url: '/home/setting/company',
    sidebarId: ModuleID.Company,
    sidebarIcon: <ShopOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'Batch',
    url: '/home/setting/batch',
    sidebarId: ModuleID.SettingBatch,
    sidebarIcon: <TeamOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'Project',
    url: '/home/setting/project',
    sidebarId: ModuleID.SettingProject,
    sidebarIcon: <FolderOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'HR',
    url: '/home/setting/hr',
    sidebarId: ModuleID.SettingHR,
    sidebarIcon: <TeamOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'Financial',
    url: '/home/setting/financial',
    sidebarId: ModuleID.SettingFinancial,
    sidebarIcon: <DollarCircleOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Setting
  },
  {
    name: 'Statistic',
    url: '/home/statistic',
    sidebarId: ModuleID.Statistic,
    sidebarIcon: <RiseOutlined />,
    isRouteExact: true,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'ProjectProgress', // ProjectProgress
    url: '/home/setting/projectprogress',
    sidebarId: ModuleID.ProjectProgress,
    sidebarIcon: <BarChartOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Statistic
  },
  {
    name: 'ProjectProgress_I', // ProjectProgress_I
    url: '/home/setting/projectprogress_I',
    sidebarId: ModuleID.ProjectProgress_I,
    sidebarIcon: <BarChartOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Statistic
  },
  {
    name: 'ProjectProgress_I2', // ProjectProgress_I2
    url: '/home/setting/projectprogress_I2',
    sidebarId: ModuleID.ProjectProgress_I2,
    sidebarIcon: <BarChartOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Statistic
  },
  {
    name: 'ProjectProgress_P', // ProjectProgress_P
    url: '/home/setting/projectprogress_P',
    sidebarId: ModuleID.ProjectProgress_P,
    sidebarIcon: <BarChartOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.Statistic
  },
  {
    name: 'StorehouseAll',
    url: '/home/storehouseAll',
    sidebarId: ModuleID.StorehouseAll,
    sidebarIcon: <HomeOutlined />,
    isRouteExact: true,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
  {
    name: 'Inventory',
    url: '/home/storehouse/inventory',
    sidebarId: ModuleID.Inventory,
    sidebarIcon: <DatabaseOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.StorehouseAll
  },
  {
    name: 'Storehouse',
    url: '/home/storehouse/storehouse',
    sidebarId: ModuleID.Storehouse,
    sidebarIcon: <DatabaseOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.StorehouseAll
  },
  {
    name: 'Material',
    url: '/home/storehouse/material',
    sidebarId: ModuleID.Material,
    sidebarIcon: <DatabaseOutlined />,
    isRouteExact: false,
    isEnableOnSideMenu: true,
    upperItem: ModuleID.StorehouseAll
  },
  {
    name: 'Test',
    url: '/home/test',
    sidebarId: ModuleID.Test,
    sidebarIcon: <ControlOutlined />,
    isRouteExact: true,
    isEnableOnSideMenu: true,
    upperItem: undefined
  },
];

// Tab
export const PageTabID = {
  Main: { id: 'Main', titleStyle: CardTitleStyle.TitleOnly, descriptionTitle: 'Main' },
  
  BaseDataCount: { id: 'BaseDataCount', titleStyle: CardTitleStyle.BaseModelName_DataCallName_DataCount },
  Data: { id: 'Data', titleStyle: CardTitleStyle.DataCallName },
  DataCount: { id: 'DataCount', titleStyle: CardTitleStyle.DataCallName_DataCount },

  Accounts_Overview: { id: 'Accounts_Overview', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Statement_Export },
  //Accounts_Receipt: { id: 'Accounts_Receipt', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Export },
  Accounts_Receipt: { id: 'Accounts_Receipt', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Menu },
  Accounts_Salary_Overview: { id: 'Accounts_Salary_Overview', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Transfer_Add },
  Accounts_Salary_Detail: { id: 'Accounts_Salary_Detail', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Accounts_Salary_OfficialOffer: { id: 'Accounts_Salary_OfficialOffer', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  Accounts_Salary_OfficialDeduction: { id: 'Accounts_Salary_OfficialDeduction', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  Accounts_Salary_ProjectBond: { id: 'Accounts_Salary_ProjectBond', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.None },
  Accounts_Salary_ProjectSubsidy: { id: 'Accounts_Salary_ProjectSubsidy', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.None },
  Accounts_Salary_RepairBond: { id: 'Accounts_Salary_RepairBond', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.None },
  Accounts_Salary_OtherBond: { id: 'Accounts_Salary_OtherBond', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  Accounts_Salary_OtherOffer: { id: 'Accounts_Salary_OtherOffer', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  Accounts_Salary_OtherDeduction: { id: 'Accounts_Salary_OtherDeduction', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  Accounts_Salary_Others: { id: 'Accounts_Salary_Others', titleStyle: CardTitleStyle.CategoryName_DataCount, buttonStyle: CardTitleButton.Add },
  //Accounts_All_ThisMonth: { id: 'Accounts_All_ThisMonth', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Accounts_All_LastMonth: { id: 'Accounts_All_LastMonth', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Settle },
  //Accounts_All_Last3Months: { id: 'Accounts_All_Last3Months', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Accounts_All_Last6Months: { id: 'Accounts_All_Last6Months', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Accounts_All_ThisYear: { id: 'Accounts_All_ThisYear', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Accounts_All_LastYear: { id: 'Accounts_All_LastYear', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  //Accounts_Salary_ThisMonth: { id: 'Accounts_Salary_ThisMonth', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Accounts_Salary_LastMonth: { id: 'Accounts_Salary_LastMonth', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Accounts_Salary_Last3Months: { id: 'Accounts_Salary_Last3Months', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Accounts_Salary_ThisYear: { id: 'Accounts_Salary_ThisYear', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Accounts_Salary_LastYear: { id: 'Accounts_Salary_LastYear', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Company_List: { id: 'Company_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Company_Basic: { id: 'Company_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Company_Basic: { id: 'Company_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Company_Bank: { id: 'Company_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },

  Customer_List: { id: 'Customer_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Customer_Basic: { id: 'Customer_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Customer_Basic: { id: 'Customer_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Customer_Bank: { id: 'Customer_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Customer_Contact: { id: 'Customer_Contact', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Customer_ContactRecord: { id: 'Customer_ContactRecord', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Customer_Invoice: { id: 'Customer_Invoice', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Customer_Project: { id: 'Customer_Project', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Link_Add },

  Document_List: { id: 'Document_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  
  HR_List: { id: 'HR_List', titleStyle: CardTitleStyle.DataCallName_DataCount_OnJobCount, buttonStyle: CardTitleButton.Add },
  //HR_Basic: { id: 'HR_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  HR_Basic: { id: 'HR_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  HR_Account: { id: 'HR_Account', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Account & Password' },
  HR_Bank: { id: 'HR_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  HR_Project: { id: 'HR_Project', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  HR_Salary: { id: 'HR_Salary', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Inventory_List: { id: 'Inventory_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Inventory_Basic: { id: 'Inventory_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },

  Material_List: { id: 'Material_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Material_Basic: { id: 'Material_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Material_Inventory: { id: 'Material_Inventory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Project_List: { id: 'Project_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Project_Basic: { id: 'Project_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Project_Basic: { id: 'Project_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Project_Balance: { id: 'Project_Balance', titleStyle: CardTitleStyle.DataCallName, buttonStyle: CardTitleButton.None },
  Project_Customer: { id: 'Project_Customer', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Link_Add_UnLink_Modify, title: 'Customer' },
  Project_Contact: { id: 'Project_Contact', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Project_Validation: { id: 'Project_Validation', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Import_Or_Modify_Delete },
  Project_ValidationItem: { id: 'Project_ValidationItem', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Project_Contract: { id: 'Project_Contract', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Project_ContractContent: { id: 'Project_ContractContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectContractContent' },
  Project_Receipt: { id: 'Project_Receipt', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Project_Cost: { id: 'Project_Cost', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Import_Add },
  Project_CustomerTransaction: { id: 'Project_CustomerTransaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Menu },
  Project_CustomerTransactionContent: { id: 'Project_CustomerTransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Project_SupplierTransaction: { id: 'Project_SupplierTransaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Menu },
  Project_SupplierTransactionContent: { id: 'Project_SupplierTransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Project_Transaction: { id: 'Project_Transaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Menu },
  Project_TransactionContent: { id: 'Project_TransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Project_Others: { id: 'Project_Others', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  
  Profile_Basic: { id: 'Profile_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Profile_Account: { id: 'Profile_Account', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Account & Password' },
  Profile_Bank: { id: 'Profile_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Profile_Project: { id: 'Profile_Project', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Profile_Salary: { id: 'Profile_Salary', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Setting_Group: { id: 'Setting_Group', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_Brand: { id: 'Setting_Brand', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_Zone: { id: 'Setting_Zone', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_Region: { id: 'Setting_Region', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_CommunicationWay: { id: 'Setting_CommunicationWay', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_CustomerLevel: { id: 'Setting_CustomerLevel', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ComingWay: { id: 'Setting_ComingWay', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_Document: { id: 'Setting_Document', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_SupplierLevel: { id: 'Setting_SupplierLevel', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_SupplyMaterial: { id: 'Setting_SupplyMaterial', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_WorkerLevel: { id: 'Setting_WorkerLevel', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_WorkerCategory: { id: 'Setting_WorkerCategory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_Department: { id: 'Setting_Department', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_JobStatus: { id: 'Setting_JobStatus', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_JobTitle: { id: 'Setting_JobTitle', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_PermissionGroup: { id: 'Setting_PermissionGroup', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProgressStatusLevel: { id: 'Setting_ProgressStatusLevel', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_ProjectBuildingCategory: { id: 'Setting_ProjectBuildingCategory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectBuildingType: { id: 'Setting_ProjectBuildingType', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectContractCategory: { id: 'Setting_ProjectContractCategory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_ProjectContractTemplate: { id: 'Setting_ProjectContractTemplate', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Setting_ProjectCategory: { id: 'Setting_ProjectCategory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Setting_ProjectCategoryCharge: { id: 'Setting_ProjectCategoryCharge', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Setting_ProjectCategoryStage: { id: 'Setting_ProjectCategoryStage', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Link },
  //Setting_ProjectComingWay: { id: 'Setting_ProjectComingWay', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectInfo: { id: 'Setting_ProjectInfo', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectLevel: { id: 'Setting_ProjectLevel', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectQuota: { id: 'Setting_ProjectQuota', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectStage: { id: 'Setting_ProjectStage', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_ProjectType: { id: 'Setting_ProjectType', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_ProjectValidationItemType: { id: 'Setting_ProjectValidationItemType', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Setting_SalaryType: { id: 'Setting_SalaryType', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_TrafficFeeTemplate: { id: 'Setting_TrafficFeeTemplate', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Setting_UserSalary: { id: 'Setting_UserSalary', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  
  Statistic_ProjectProgress: { id: 'Statistic_ProjectProgress', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressResponse: { id: 'Statistic_ProjectProgressResponse', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressUser: { id: 'Statistic_ProjectProgressUser', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressAssistDesign: { id: 'Statistic_ProjectProgressAssistDesign', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressAssistEngineering: { id: 'Statistic_ProjectProgressAssistEngineering', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressBrand: { id: 'Statistic_ProjectProgressBrand', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressRegion: { id: 'Statistic_ProjectProgressRegion', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressPreparing: { id: 'Statistic_ProjectProgressPreparing', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressPreparingActive: { id: 'Statistic_ProjectProgressPreparingActive', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressOngoing: { id: 'Statistic_ProjectProgressOngoing', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Statistic_ProjectProgressUserLoadingResponsed: { id: 'Statistic_ProjectProgressUserLoadingResponsed', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  //Statistic_ProjectProgressUserLoadingAssisted: { id: 'Statistic_ProjectProgressUserLoadingAssisted', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressResponseLoadingTitle: { id: 'Statistic_ProjectProgressResponseLoadingTitle', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressUserLoadingDistinct: { id: 'Statistic_ProjectProgressUserLoadingDistinct', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressUserLoadingTitle: { id: 'Statistic_ProjectProgressUserLoadingTitle', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgressUserLoadingUser: { id: 'Statistic_ProjectProgressUserLoadingUser', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Statistic_ProjectProgress_ProjectValidation: { id: 'Statistic_ProjectProgress_ProjectValidation', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Storehouse_List: { id: 'Storehouse_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Storehouse_Basic: { id: 'Storehouse_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Storehouse_Inventory: { id: 'Storehouse_Inventory', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Inventory_StockOut_StockIn },
  Storehouse_FormList: { id: 'Storehouse_FormList', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Inventory_StockOut_StockIn },
  Storehouse_FormContent: { id: 'Storehouse_FormContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton, title: 'StockFormContent' },

  Supplier_List: { id: 'Supplier_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Supplier_Basic: { id: 'Supplier_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Supplier_Basic: { id: 'Supplier_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Supplier_Bank: { id: 'Supplier_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Supplier_Contact: { id: 'Supplier_Contact', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Supplier_Invoice: { id: 'Supplier_Invoice', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Supplier_Validation: { id: 'Supplier_Validation', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Supplier_Transaction: { id: 'Supplier_Transaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Supplier_TransactionContent: { id: 'Supplier_TransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Supplier_Accounts: { id: 'Supplier_Transaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.StatementSupplier },

  Task_Overview: { id: 'Task_Overview', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Task_Financial: { id: 'Task_Financial', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Task_Project: { id: 'Task_Project', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Task_Storehouse: { id: 'Task_Storehouse', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Task_TransactionContent: { id: 'Task_TransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Task_TransactionContentProject: { id: 'Task_TransactionContentProject', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },

  Transaction_Overview: { id: 'Transaction_Overview', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Menu },
  Transaction_Receipt: { id: 'Transaction_Receipt', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },

  Worker_List: { id: 'Worker_List', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  //Worker_Basic: { id: 'Worker_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Worker_Basic: { id: 'Worker_Basic', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.Modify, title: 'Info' },
  Worker_Bank: { id: 'Worker_Bank', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Worker_Contact: { id: 'Worker_Contact', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Worker_Invoice: { id: 'Worker_Invoice', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.Add },
  Worker_Validation: { id: 'Worker_Validation', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Worker_Transaction: { id: 'Worker_Transaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.None },
  Worker_TransactionContent: { id: 'Worker_TransactionContent', titleStyle: CardTitleStyle.TitleOnly, buttonStyle: CardTitleButton.None, title: 'ProjectTransactionContent' },
  Worker_Accounts: { id: 'Worker_Transaction', titleStyle: CardTitleStyle.DataCallName_DataCount, buttonStyle: CardTitleButton.StatementSupplier },
}

export const FormType = {
  None: { ID: '0000', Name: 'None', Model: undefined, ApplyTab: [] },

  // Storehouse
  StockIn: { ID: '0010', Name: 'StockIn', Model: winStockForm, ApplyTab: [PageTabID.Storehouse_FormList] },
  StockInventory: { ID: '0011', Name: 'StockInventory', Model: winStockForm, ApplyTab: [PageTabID.Storehouse_FormList] },
  StockOut: { ID: '0012', Name: 'StockOut', Model: winStockForm, ApplyTab: [PageTabID.Storehouse_FormList] },

  // Income
  ProjectReceipt: { ID: '0100', Name: 'ProjectReceipt', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectIn: { ID: '0101', Name: 'ProjectIn', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  EngineeringBondIn: { ID: '0102', Name: 'EngineeringBondIn', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectReceiptSup: { ID: '0150', Name: 'ProjectReceiptSup', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },

  // Outcome
  SupplierExpenditure: { ID: '0200', Name: 'SupplierExpenditure', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectOut: { ID: '0201', Name: 'ProjectOut', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  EngineeringBondOut: { ID: '0202', Name: 'EngineeringBondOut', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  SiteAllowance: { ID: '0203', Name: 'SiteAllowance', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  SupplierCommission: { ID: '0204', Name: 'SupplierCommission', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectTrafficFee: { ID: '0205', Name: 'ProjectTrafficFee', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectAward: { ID: '0206', Name: 'ProjectAward', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  ProjectRepair: { ID: '0207', Name: 'ProjectRepair', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },

  // Salary
  UserSalary: { ID: '0300', Name: 'UserSalary', Model: winStockForm, ApplyTab: [] },

  // Others
  OfficeIn: { ID: '0400', Name: 'OfficeIn', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  OfficeOut: { ID: '0450', Name: 'OfficeOut', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  OfficeAllowance: { ID: '0451', Name: 'OfficeAllowance', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
  AccountTransfer: { ID: '0500', Name: 'AccountTransfer', Model: winAccountForm, ApplyTab: [PageTabID.Project_Transaction] },
}

export const FormCategory = {
    None: '0000',
    Project: "1000",
    Storehouse: '6000',
    Financial: '7000',
    Salary: '7001'
}

export const getFormTypeByID = (id) => {
  let ret = undefined;

  switch (id) {
    case FormType.StockInventory.ID: { ret = FormType.StockInventory; break; }
    case FormType.ProjectReceipt.ID: { ret = FormType.ProjectReceipt; break; }
    case FormType.ProjectIn.ID: { ret = FormType.ProjectIn; break; }
    case FormType.EngineeringBondIn.ID: { ret = FormType.EngineeringBondIn; break; }
    case FormType.ProjectReceiptSup.ID: { ret = FormType.ProjectReceiptSup; break; }
    case FormType.SupplierExpenditure.ID: { ret = FormType.SupplierExpenditure; break; }
    case FormType.ProjectOut.ID: { ret = FormType.ProjectOut; break; }
    case FormType.EngineeringBondOut.ID: { ret = FormType.EngineeringBondOut; break; }
    case FormType.SiteAllowance.ID: { ret = FormType.SiteAllowance; break; }
    case FormType.SupplierCommission.ID: { ret = FormType.SupplierCommission; break; }
    case FormType.ProjectTrafficFee.ID: { ret = FormType.ProjectTrafficFee; break; }
    case FormType.ProjectAward.ID: { ret = FormType.ProjectAward; break; }
    case FormType.ProjectRepair.ID: { ret = FormType.ProjectRepair; break; }
    case FormType.UserSalary.ID: { ret = FormType.UserSalary; break; }
    case FormType.OfficeIn.ID: { ret = FormType.OfficeIn; break; }
    case FormType.OfficeOut.ID: { ret = FormType.OfficeOut; break; }
    case FormType.OfficeAllowance.ID: { ret = FormType.OfficeAllowance; break; }
    case FormType.AccountTransfer.ID: { ret = FormType.AccountTransfer; break; }
    default: break;
  }

  return ret;
}

export const PermissionType = {
  None: 0,
  ReadOnly: 1,
  Editable: 2
}

export const ProgressStatusID = {
  Stage1: '0000',
  Stage2: '0001',
  Stage3: '0002',
  Stage4: '0003',
  Stage5: '0004',
  Stage6: '0005',

  Executed: '0100',
  Cancelled: '0101',

  ActionNone: '2000',
  ActionSentFrom: '2001',
  ActionBackFrom: '2002',
  ActionVerify: '2003',
}

export const ProjectContractCategoryID = {
  Measurement: '0000',                            // [演拓]提案丈量意向書
  PurposeWoMeasurement: '0001',                   // [演拓]提案意向書(無需丈量)
  Design: '0002',                                 // [演拓]室內裝修設計合約
  PresaleCustomization: '0003',                   // [演拓]預售屋設計變更合約
  EngineeringTaipei: '0004',                      // [演拓]室內裝修工程承攬合約(北部高雄)
  EngineeringTaichung: '0005',                    // [演拓]室內裝修工程承攬合約(台中)
  Supplementary: '0006',                          // [演拓]室內裝修工程承攬合約追加減合約
  Termination: '0007',                            // [演拓]合約終止協議書
  DesignOnly: '0008',                             // [演拓]室內裝修純設計合約
  G_Examination: '0009',                          // [通用]裝修不送審切結書
  G_CH2O: '0010',                                 // [通用]不施作除甲醛工程切結書
  G_DrawingConfirm: '0011',                       // [通用]施工圖簽認書
  G_ConfirmBefore: '0012',                        // [通用]設計簽約前說明書
  I_Measurement: '0013',                          // [演亦]提案丈量意向書
  I_PurposeWoMeasurement: '0014',                 // [演亦]提案意向書(無需丈量)
  I_Design: '0015',                               // [演亦]室內裝修設計合約
  I_PresaleCustomization: '0016',                 // [演亦]預售屋設計變更合約
  I_EngineeringTaipei: '0017',                    // [演亦]室內裝修工程承攬合約(北部高雄)
  I_EngineeringTaichung: '0018',                  // [演亦]室內裝修工程承攬合約(台中)
  I_Supplementary: '0019',                        // [演亦]室內裝修工程承攬合約追加減合約
  I_Termination: '0020',                          // [演亦]合約終止協議書
  I_DesignOnly: '0021',                           // [演亦]室內裝修純設計合約
  P_Measurement: '0022',                          // [之貳]丈量意向書
  P_Design: '0023',                               // [之貳]設計合約
  P_PresaleCustomization: '0024',                 // [之貳]預售屋設計變更合約
  P_Engineering: '0025',                          // [之貳]工程合約
  P_Supplementary: '0026',                        // [之貳]室內裝修工程承攬合約追加減合約
  P_Termination: '0027',                          // [之貳]合約終止協議書
  P_PurposeWoMeasurement: '0028',                 // [之貳]提案意向書

  DesignFlexible: '1002',                         // [演拓]室內裝修設計合約 (彈性時程)
  DesignOnlyFlexible: '1008',                     // [演拓]室內裝修純設計合約 (彈性時程)
  P_DesignFlexible: '1023',                       // [之貳]設計合約 (彈性時程)

  PresalePurposal: '1100',                        // [演拓]預售屋提案意向書
  Suspend: '1101',                                // [演拓]合約中止協議書
  PresaleTermination: '1102',                     // [演拓]預售屋客變合約終止協議書
  P_PresalePurposal: '1120',                      // [之貳]預售屋提案意向書
  P_Suspend: '1121',                              // [之貳]合約中止協議書
  P_PresaleTermination: '1122',                   // [之貳]預售屋客變合約終止協議書

  G_WoExamination: '2000',                        // [通用]裝修送審不委託書
}

export const ProjectViewType = {
  None: '0000',
  Self: '1000',
  All: '2000'
}

export const ReceiptFlow = {
  Receive: '0000',
  Issue: '0001',
}

export const SysModelType = {
  T_Reserved: 'T_Reserved',
  T_All: 'T_All',
  T_Account: 'T_Account',
  T_Basic: 'T_Basic',
  T_Data: 'T_Data',
  T_Form: 'T_Form',
  T_Record: 'T_Record',
  T_Salary: 'T_Salary',
  T_System: 'T_System',
  T_Task: 'T_Task'
}

export const TableAction = {
  Add: 'Add',
  Delete: 'Delete',
  Modify: 'Modify',
  Verify: 'Verify',
  Link: 'Link',
  Unlink: 'Unlink',
  Up: 'Up',
  Down: 'Down',

  Addsub: 'Addsub',
  Deletesub: 'Deletesub',
  Modifysub: 'Modifysub'
}

export const TableActionMenuType = {
  None: 'None',
  Delete: 'Delete',
  Delete_Verify: 'Delete_Verify',
  Download_Modify_Delete: 'Download_Modify_Delete',
  Export: 'Export',
  Export_Supplement_Award_Reset: 'Export_Supplement_Award_Reset',
  Export_Modify_Delete: 'Export_Modify_Delete',
  Export_Modify_Delete_Transfer_Award: 'Export_Modify_Delete_Transfer_Award',
  FinancialView_QuoteView_Modify_Delete_Verify: 'FinancialView_QuoteView_Modify_Delete_Verify',
  Modify: 'Modify',
  Modify_Delete: 'Modify_Delete',
  Modify_Delete_Supplement: 'Modify_Delete_Supplement',
  Modify_Delete_Supplement_Reset: 'Modify_Delete_Supplement_Reset',
  Modify_Delete_Verify: 'Modify_Delete_Verify',
  Modify_Unlink: 'Modify_Unlink',
  Unlink: 'Unlink',
  Up_Down_Modify: 'Up_Down_Modify',
  Up_Down_Modify_Delete: 'Up_Down_Modify_Delete',
  Up_Down_Modify_Delete_Addsub: 'Up_Down_Modify_Delete_Addsub',
  Up_Down_Modify_Delete_Copy: 'Up_Down_Modify_Delete_Copy',
  Up_Down_Modify_Delete_Link: 'Up_Down_Modify_Delete_Link',
  Up_Down_Modifysub_Deletesub: 'Up_Down_Modifysub_Deletesub',
  Up_Down_Unlink: 'Up_Down_Unlink',

  Form_Execute: 'Form_Execute',
  Form_Receipt: 'Form_Receipt',
  Form_SendToStage2_Modify_Cancel: 'Form_SendToStage2_Modify_Cancel',
  Form_SendToStage2_Modify_Delete: 'Form_SendToStage2_Modify_Delete',
  Form_SendToStage3: 'Form_SendToStage3',
  Form_SendToStage3_Modify: 'Form_SendToStage3_Modify',
  Form_SendToStage4: 'Form_SendToStage4',
  Form_SendToStage4_Modify: 'Form_SendToStage4_Modify',
  Form_SendToStage5: 'Form_SendToStage5',
  Form_SendToStage6: 'Form_SendToStage6',
  Form_Verify_Stage3: 'Form_Verify_Stage3',
  Form_Verify_Stage4: 'Form_Verify_Stage4',
  Form_Verify_Stage5: 'Form_Verify_Stage5',
  Form_Verify_Stage6: 'Form_Verify_Stage6',
}

export const SalaryCategory = {
  OfficialOffer: '0000',
  OfficialDeduction: '0030',
  ProjectBond: '0050',
  ProjectSubsidy: '0051',
  RepairBond: '0052',
  OtherBond: '0060',
  OtherOffer: '0090',
  OtherDeduction: '0100',
  Others: '0200'
}

export const SpecificStr = {
  TimeOfContractSigning: '簽約時',
  TimeOfElevationDrawing: '設計立面圖提交',
  TimeOfSupplementary: '核對追加減工程款內容無誤後',
  TimeOfEngineeringStr1: '開工後',
  TimeOfEngineeringStr2: '工作天或油漆工程退場時(依時間或實際工程進度先到達者為主',
  TimeOfEngineeringStr3: '完工驗收後一星期內',
  TimeOfTermination: '收到甲方所提供之退款帳號及簽回之合約終止協議書後',
}

export const StorehouseTypeID = {
  Internal: '0000',
  External: '0001',
}


